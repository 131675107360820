.homeContainer{
    margin-top: 2vh ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}
.homeCardsContainer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.homeTitle{
    font-family: 'Montserrat','Poppins';
    font-weight: 500;
    font-size: 1.5em;
    margin-bottom:12px ;
}