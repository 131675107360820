.imageContainer {
    width: 100%;
    height: 50vh;
    border-radius: 0px 40px 0px 0px;
}

.contestInfo {
    font-family: 'Montserrat', sans-serif;
    padding: 0px;
    border-radius: 0px 40px 0px 40px;
}

.subContainers {
    padding: 5px 35px;
}

.contestInfoContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* flex-direction: column; */
}

.nameContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.75em;
}
.contestPagebtn{
    margin: 3px;
    padding: 10px;
    border: 2px solid black;
    border-radius: 20px;
    
    color: black;
    background-color: white;
}
.contestPagebtn:hover{
    cursor: pointer
    ;
    
    background-color: black;
    color: white;
}
.status {
    text-align: center;
    font-weight: 500;
    font-size: 0.95em;
    width: max-content;
    padding: 5px 14px 5px 8px;
    border-bottom-right-radius: 18px;
    color: white;
    backdrop-filter: blur(5px);
    letter-spacing: 5px;
}

.valueContainer {
    font-weight: 600;
    overflow-wrap: break-word;
    /* box-shadow: 0px 0px 2px rgb(26, 26, 26, 0.3); */
    padding: 0.5rem
}

.desc {
    box-shadow: 0px 0px 2px rgb(26, 26, 26, 0.3);
    border-radius: 4px;
    padding: 8px 5px;
}

.rightCol {
    text-align: right;
    display: flex;
    flex-direction: column;
}

/* rules */
.contestRules,
.contestPrize,
.contestTags {
    padding-top: 0;
}

.contestTags {
    margin-bottom: 15px;
}

.rulesTitle,
.prizeTitle,
.TagsTitle {
    font-size: 1.25em;
    font-weight: 600;
}

.subRule,
.subPrize {
    margin-left: 20px;
    margin-top: 12px;
    font-weight: 500;
    font-size: 0.95em;
}

.subRule {
    display: list-item;
    list-style-type: disc;
}

/* rules */
/* prizes */
.subPrizes {
    margin: 15px;
    margin-left: 0;
    padding: 8px 12px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 2px 2px 8px rgb(26, 26, 26, 0.2);
    border-left: 2px solid var(--color-bg-dark);
}

.inlineContainer {
    display: flex;
    flex-direction: column;
}

.inlineContainer>div {
    margin-right: 25px;
}

.prizeDesc {
    margin-top: 8px;
    margin-bottom: 8px;
    display: block;
}

/* prizes */
/* tags */
.singleTag {
    padding: 8px 15px;
    background-color: white;
    box-shadow: 2px 2px 8px rgb(26, 26, 26, 0.2);
    border-left: 2px solid var(--color-bg-dark);
    margin: 12px;
    box-shadow: 2px 2px 5px rgb(26, 26, 26, 0.2);
    border-radius: 5px;
    width: max-content;
}

/* tags */

/* view question */
.BasicInfo>* {
    margin-right: 13px;
    padding-right: 13px;
    border-right: 0.5px solid black;
}

.lastChild {
    border-right: none;
}

.descContainer {
    margin-top: 18px;
}

/* view question */





.warningIcon{
    color:peru;
    display: inline;
    font-size: 1.65rem;
}