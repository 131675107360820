.navBar{
    padding: 12px 15px;
    height: 8vh;
    background-color: var(--color-primary-800);
    display: flex;
    justify-content: space-between;
    align-items: center;    
    color:white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
}
.navCompany{
    display: flex;
    justify-content: center;
    align-items: center;
}
.navBar img.navLogo{
    height: 5vh;
    margin-right: 15px;
}
.navName{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2em;
}
.navMotto{
    font-size: 0.5em;
    opacity: 0.6;
}
.navSingleLink{
    display: flex;
    justify-content: center;
    align-items: center;
}
.navLinkSym{ 
    color: white; 
    font-size: 1.2em; 
    margin-Right: 8px; 
}
.navSingleLink>*:hover,.navSingleLink>*:hover .navLinkSym{
    color: var(--color-accent-washed);
}
.navSingleLink>*{
    margin: 0px 8px;
}