.createBody {
    width: 100%;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.createContainer {
    width: 80vw;
    border-radius: 3px;
    background-color: white;
    padding: 15px 15px;
    box-shadow: 2px 2px 12px rgb(26, 26, 26, 0.4);
    margin: 5vh 0vh;
    display: flex;
    flex-direction: column;
}

input:invalid:focus {
    background: pink;
}

.blockContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
}

input,
textarea {
    outline: none;
    border: 0.5px solid rgba(255, 255, 255, 0)
}

input:focus,
textarea:focus {
    outline: none;
    border: 0.5px solid var(--color-bg-dark);
}

.perInput,
.perSubInput {
    padding: 5px 5px;
    margin: 5px 0px;
    /* border-radius: 5px; */
    display: flex;
    flex-direction: column;
}

.perSubInput .perInput {
    margin: 0;
}

.subPrizeContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.8px solid var(--color-bg-dark);
    width: 52vw;
    margin: 5px 0px;
}

.perInput input,
.perInput textarea {
    margin: 1px 0px;
    background-color: rgb(230, 237, 244);
    padding: 2px 5px;
}

.perInput input::placeholder,
.perInput textarea::placeholder {
    font-family: "Roboto";
    font-weight: 300;
    opacity: 0.85;
}

.inputTitle,
.addBtn {
    background-color: var(--color-bg-dark);
    width: max-content;
    color: white;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 0.85em;
    padding: 3px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.addBtn {
    font-size: 1em;
    cursor: pointer;
}

.inputTitle,
.perInput input {
    height: 5vh;

}

.perInput {
    display: flex;
    flex-direction: row;
}

.formDescription {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.formDescription textarea {
    width: 100%;
    overflow: auto;
    height: 20vh;
    resize: none;
}

.blockContainer .perInput {
    align-items: center;
}

.formPrize {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* sponsors */

.sponsors,
.rules,
.tags {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.singleInput {
    width: 100%;
    margin: 0px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.singleInput input {
    margin: 0px 12px;
}

.sponsorContainer,
.rulesContainer,
.tagContainer {
    display: flex;
    flex-direction: column;
}

/* sponsors */

/* Dates */
.formDates {
    margin: 3vh 0;
    width: 40vw;
    display: flex;
    justify-content: space-between;
}

.datePicker {
    margin: 15px;
}

/* Dates */
/* submit btn */
.submitBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subBtn {
    margin-bottom: 15px;
    padding: 8px;
    cursor: pointer;
    font-size: 1.1em;
    font-family: "Montserrat";
    font-weight: 500;
    color: white;
    background-color: var(--color-bg-dark);
}

/* submit btn */
/* error */
.codeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.codeContainer .perInput {
    margin-top: 0;
    padding-top: 0;
    padding-left: 0;
}

.errorText {
    color: red;
    font-size: 0.85em;
}

/* error */