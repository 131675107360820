.imgBodyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cardImage {
    width: 30vw;
    height: 25vh;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
    /* border-left: 3px solid var(--color-bg-light); */
}

.cardContainer {
    font-family: 'Poppins', 'Montserrat', sans-serif;
    background-color: white;
    /* background-color: var(--color-primary-700); */
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
    margin: 0vh 2vh;
    margin-bottom: 4vh;
    padding: 12px;
    width: 30vw;
    min-height: 18vh;
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    /* border-left: 3px solid var(--color-bg-light); */
}

.cardBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30vw;
    box-shadow: 12px 0px 8px -6px rgba(0, 0, 0, 0.28);
    border-right: 0.5px solid var(--color-bg-dark);
}

.contestHeader,
.contestFooter {
    display: flex;
    justify-content: space-between;
    margin-right: 18px;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: 0.82em;
}

.contestDate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
}

.contestHeader {
    justify-content: flex-start
}

.contestCode {
    display: flex;
    align-items: center;
}

.footerChild {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 5px;
}

.contestName {
    font-size: 1.2em;
    margin: 8px 0px;
    font-weight: 500;
}

.boldThis {
    font-weight: 500;
}

.cardHead,
.contestTime {
    font-size: 0.9em;
    opacity: 0.8;
}

.editsOpts {
    width: 5vw;
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.editsOpts>* {
    margin: 8px;
}

@media screen and (max-width:948px) {
    .cardContainer {
        width: 90vw;
    }

    .cardBody {
        width: 70vw;
    }

    .editsOpts {
        width: 10vw;
    }
}