@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto+Mono:wght@300;400;500;700&family=Lato:wght@300;400;700&family=Montserrat:wght@300;400;500;600;700;800&family=Noto+Sans:wght@300;400;500&family=Sora:wght@300;400;500;600&family=Source+Code+Pro:wght@300;400;500;600&family=Martel+Sans:wght@400;600;700;800&family=Raleway:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&family=Lobster+Two:ital,wght@0,400;0,700;1,400&family=Public+Sans:ital,wght@0,300;0,400;0,500;0,700;1,600&family=Righteous&display=swap');

/* font-family: 'Montserrat', sans-serif;
font-family: 'Poppins', sans-serif; 
font-family: 'Raleway', sans-serif; 
font-family: 'Roboto', sans-serif;
font-family: 'Martel Sans', sans-serif;
font-family: 'Lato', sans-serif;
font-family: 'Noto Sans', sans-serif;
font-family: 'Sora', sans-serif;
font-family: 'Source Code Pro', monospace;
font-family: 'Baloo 2', cursive;
font-family: 'Lobster Two', cursive;
font-family: 'Public Sans', sans-serif;
font-family: 'Righteous', sans-serif;
*/

:root {
  --color-button-text: #fff;
  --color-primary-100: #e6eef8;
  --color-primary-200: #b2bdcd;
  --color-primary-300: #5d7290;
  --color-primary-600: #323d4d;
  --color-primary-700: #242c37;
  --color-primary-800: #151a21;
  --color-primary-900: #0b0e11;
  --color-secondary-washed-out: #37649e;
  --color-secondary: #1d2a36;
  --color-secondary-dark: #3158e6;
  --color-accent-glow: rgba(253, 77, 77, 0.3);
  --color-accent: #ff7700;
  --color-accent-washed: #ff902ead;
  --color-bg-dark: #0f1527;
  --color-bg-light: #1c3351;
  --color-white: #ffffff;
  --theme-bg-color: #10121b66;
  --color-bg-dark-shade: #0f1527be;
  --color-bg-grey-shade: rgb(235, 235, 235);
}

body {
  background-color: var(--color-bg-grey-shade);
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus {
  outline: none !important;
  border: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* scroll bar */
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-accent-glow);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-accent);
}

/* scroll bar */
/* loader */
.loader {
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* loader */

.input {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #0c0c0c;
  color: #565656;
  -webkit-appearance: none;
}

.input:focus {
  border-color: cornflowerblue;
  outline: none;
}

.input.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.evaluating{
  animation: rotating 1s infinite;
}
@keyframes rotating {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

.form-input{
  border: 2px solid rgba(0, 0, 0, 0.30);
  border-radius: 5px;
  padding: 3px 5px;
  width: 25vw;
}
.form-input:focus{
  border: 2px solid black;
}