.fieldsContainer{
    background-color: white;
    width: 70vw;
    box-shadow: 2px 2px 8px rgba(0, 0, 0,0.5);
    height: max-content;
    margin: 25px;
    padding: 25px;
}
.textContainer{
    font-family: 'Montserrat', sans-serif;
    margin: 5px 8px;
    font-weight: 500;
}
.codeNameContainer{
    display: flex;
    align-items: center;
}
.codeName,.codeNameText{
    margin-bottom: 12px;
    height: 5vh;
}
.codeName{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    padding: 5px 8px;
    background-color: var(--color-bg-dark);
    color: white;
}
.codeNameText,.questionText{
    border: 0.5px solid var(--color-bg-dark);
    padding: 2px 5px;
    width: 100%;
}
.questionContainer{
    width: 100%;
    margin-top: 14px;
    margin-bottom: 14px;
}
.questionName{
    display: flex;
    align-items: center;
}
.questionName .Qtitle{
    width: max-content;
}
.render{
    margin: 5px 0px;
    cursor: pointer;
}
.choice{
    display: flex;
    align-items: center;
    justify-content: center;
}
.questionText{
    height: 50vh;
}
.questionText textarea{
    overflow-wrap: break-word;
    overflow-x:hidden;
    overflow-y: scroll;
    resize: none;
}
.questionText textarea:focus{
    outline:none;
    border: none;
}
.inline{
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}